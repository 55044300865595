












import { Component } from 'vue-property-decorator';

import Field from './Field.vue';

@Component
export default class InputField extends Field {

}
